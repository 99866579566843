import { EDMDocument } from './edm-document.model';
import { MediacalPractice } from './medical-practice.model';
import { UserSubscription } from './user-subscription.model';

export class User {
  id : string;
  sub: string;
  firstname : string;
  lastname : string;
  email : string;
  address : string;
  phoneNumber : string;
  birthDate : Date;
  trainingSchool : string;
  status : string;
  adeliNumber : string;
  otherCertifications : string;
  companyName : string;

  activeSubscription: UserSubscription;

  medicalPractices: MediacalPractice[];
  currentMedicalPractice: string;
  file: EDMDocument;

  constructor(data: any) {
    this.id = data?.id;
    this.sub = data?.sub;
    this.firstname = data?.firstname;
    this.lastname = data?.lastname;
    this.email = data?.email;
    this.address = data?.address;
    this.phoneNumber = data?.phoneNumber;
    this.birthDate = data?.birthDate ? new Date(data.birthDate) : null;
    this.trainingSchool = data?.trainingSchool;
    this.status = data?.status;
    this.adeliNumber = data?.adeliNumber;
    this.otherCertifications = data?.otherCertifications;
    this.companyName = data?.companyName;
    this.medicalPractices = data?.medicalPractices;
    this.currentMedicalPractice = data?.currentMedicalPractice;
  }
}
