export enum ApiEndpoints {
    Patients = '/patients',
    Antecedents = '/antecedents',
    Consultations = '/consultations',
    ConsultationTypes = '/consultations/types',
    ConsultationActs = '/consultations/acts',
    Users = '/users',
    OAuth0MailVerification = '/jobs/verification-email',
    OAuth0MailJobs = '/jobs',
    Types = '/types',
    MedicalPractices = '/medicalpractices',
    Documents = '/documents',
    Calendars = '/calendars',
    Minimal = '/minimal',
    Subscriptions = '/subscriptions',
    Plans = '/plans',
    Coupons = '/coupons',
    PromotionCodes = '/promotion-codes',
    SetupIntent = '/setup_intent',
    Undo = '/undo',
    Cancel = '/cancel',
    Invoices = '/invoices',
    Last = '/last',
    Status = '/status',
}
