import { Observable } from 'rxjs';
import { EDMDocument } from './edm-document.model';
import { MedicalHistory } from './medical-history.model';
import { Treatment } from './treatment.model';

export class MedicalHistoryExtended extends MedicalHistory {
    [key: string]: string | number | Date | any[] | undefined,

    // patientId: string,
    // id: string,
    // year: number,
    // title: string,

    observation: string;
    documents: EDMDocument[];
    treatments: Treatment[];

    constructor(data?: any, documents$?: Observable<EDMDocument[]>) {
      super(data);

      if (data) {
        this.observation = data.observation;
        this.treatments = data.treatments;
      }

      if (documents$) {
        documents$.subscribe((documents: EDMDocument[]) => {
          this.documents = documents || [];
        });
      }
    }
}
