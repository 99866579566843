import { Additional } from './additional.model';
import { Patient } from './patient.model';
import { MedicalHistoryTab } from './medical-history-tab.model';
import { ConsultationExtended } from './consultation-extended.model';

export class PatientExtended extends Patient {
    [key: string]: string | number | Date | any | any[] | undefined,

    additionalData: Additional[];
    consultations: ConsultationExtended[];
    antecedentsTypes: MedicalHistoryTab[];
}
