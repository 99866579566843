import { LogLevelEnum } from '@amaris/lib-highway/enums';

export const environment = {
  name: 'core',
  production: false,
  assetsPath: '/assets',
  logLevel: LogLevelEnum.Debug,
  signalRUrl: '/signalr',
  apiUrl: '/api/v1',
  oAuth0Url: 'https://auth0.staging.plaiad.com/api/v2',
  oAuthProvider: {
    domain: 'auth0.staging.plaiad.com',
    clientId: 'r6l7ZM9TjiQrm3U9xPVyn2jHXk3LVkmT',
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://plaiad-dev.eu.auth0.com/api/v2/',
      scope: 'openid profile email offline_access',
    },
  },
  stripe: {
    publicKey: 'pk_test_51OybGaDD19TY2CV1LFuDLAlJE2uMnNAKNDQ4cQXd1xcHyu1T4zXjBVh7QIlMLZ3aWuZl7p4BpO9niupaodByesrb000a2bjsjI',
  },
};
