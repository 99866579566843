export class MedicalHistory {
    [key: string]: string | any | undefined,

    typeId: string;
    patientId: string;
    id: string;
    date: Date;
    antecedent: string;

    constructor(data?: any) {
      if (data) {
        this.typeId = data.typeId;
        this.patientId = data.patientId;
        this.id = data.id;
        this.date = data.date;
        this.antecedent = data.antecedent;
      }
    }
}
