import { TableData } from '@amaris/lib-highway/models';
import { GenderEnum } from '../enums/gender.enum';

export class Patient extends TableData {
    [key: string]: string | Date | any | undefined,

    id: string;
    firstname: string;
    lastname: string;
    birthDate: Date;
    phone?: string;
    email?: string;

    gender: GenderEnum;
    address: string;
    work: string;

    maidenName?: string;
    pregnant?: boolean;
    sport?: string;
    socialSecurityNumber?: string;
    doctor?: string;
    // specializedDoctor?: string;

    consultationLatestVisitDate?: Date;
    consultationReasonOfVisit?: string;
}
