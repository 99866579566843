export class MediacalPractice {
  id: string;
  companyName: string;
  defaultConsultationPrice: number;
  defaultConsultationReason: string;
  officeAddress: string;
  city: string;
  postalCode: string;
  siret: string;

  constructor(companyName?: string) {
    this.companyName = companyName;
    if (companyName) {
      this.defaultConsultationPrice = 0;
      this.defaultConsultationReason = '';
      this.officeAddress = '';
      this.siret = '';
      this.city = '';
      this.postalCode = '';
    }
  }
}
