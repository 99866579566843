import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User as OAuthUser } from '@auth0/auth0-angular';
import { User } from '../../models/user.model';
import { ConsultationExtended } from '../../models/consultation-extended.model';
import { PatientExtended } from '../../models/patient-extended.model';
import { ColorDropdown, Dropdown } from '../../models/dropdown.model';
import { Patient } from '../../models/patient.model';
import { ActData } from '../../models/act-data.model';
import { MedicalHistoryExtended } from '../../models/medical-history-extended.model';
import { MediacalPractice } from '../../models/medical-practice.model';
import { EDMDocument } from '../../models/edm-document.model';
import { ConsultationEvent } from '../../models/consultation-event.model';
import { PatientMinimal } from '../../models/patient-minimal.model';
import { UserSubscription } from '../../models/user-subscription.model';
import { SubscriptionOfferGroup } from '../../models/subscription-offer-group.model';

@Injectable({
  providedIn: 'root',
})
export class DataStoreService {
  public mobileMenuVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public connectedUser$: BehaviorSubject<User> = new BehaviorSubject<User>(new User(null));
  public connectedUserSubscription$: BehaviorSubject<UserSubscription> = new BehaviorSubject<UserSubscription>(null);
  public oAuthUser$: BehaviorSubject<OAuthUser> = new BehaviorSubject<OAuthUser>(new OAuthUser());

  public currentPatientExtended$: BehaviorSubject<PatientExtended> = new BehaviorSubject<PatientExtended>(new PatientExtended());
  private currentPatientExtendedConsultations: ConsultationExtended[] = [];
  public consultationActeData$: BehaviorSubject<ActData[]> = new BehaviorSubject<ActData[]>([]);
  public currentAntecedent$: BehaviorSubject<MedicalHistoryExtended> = new BehaviorSubject<MedicalHistoryExtended>(new MedicalHistoryExtended());
  public currentAntecedentDocuments$: BehaviorSubject<EDMDocument[]> = new BehaviorSubject<EDMDocument[]>([]);

  public patients$: BehaviorSubject<Patient[]> = new BehaviorSubject<Patient[]>([]);
  public minimalPatients$: BehaviorSubject<PatientMinimal[]> = new BehaviorSubject<PatientMinimal[]>([]);

  public currentConsultationDocuments$: BehaviorSubject<EDMDocument[]> = new BehaviorSubject<EDMDocument[]>([]);

  public medialPractices$: BehaviorSubject<MediacalPractice[]> = new BehaviorSubject<MediacalPractice[]>([]);
  public currentMedialPractice$: BehaviorSubject<MediacalPractice> = new BehaviorSubject<MediacalPractice>(new MediacalPractice());

  public currentCalendarWeekEvents$: BehaviorSubject<ConsultationEvent[]> = new BehaviorSubject<ConsultationEvent[]>([]);

  public subscriptionPlans$: BehaviorSubject<SubscriptionOfferGroup[]> = new BehaviorSubject<SubscriptionOfferGroup[]>([]);

  // dropdowns
  public antecedentsTypes$: BehaviorSubject<Dropdown[]> = new BehaviorSubject<Dropdown[]>([]);
  public consultationTypes$: BehaviorSubject<ColorDropdown[]> = new BehaviorSubject<ColorDropdown[]>([]);
  public medicalPracticesDropdown$: BehaviorSubject<Dropdown[]> = new BehaviorSubject<Dropdown[]>([]);

  public connectedUserSubscriptionEventEmitted: EventEmitter<UserSubscription> = new EventEmitter<UserSubscription>();

  public paymentAwaitingValidation$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() {}

  // payment
  setPaymentAwaitingValidation(paymentLink: string): void {
    this.paymentAwaitingValidation$.next(paymentLink);
  }

  toggleMobileMenu(): void {
    this.mobileMenuVisible$.next(!this.mobileMenuVisible$.value);
  }

  setMobileMenuVisible(visible: boolean): void {
    this.mobileMenuVisible$.next(visible);
  }

  // Subscription
  setSubscriptionPlans(plans: SubscriptionOfferGroup[]): void {
    this.subscriptionPlans$.next(plans);
  }

  setConnectedUserSubscriptionRenwable(renewable: boolean): void {
    this.connectedUserSubscription$.value.renewable = renewable;
    this.connectedUserSubscription$.next(this.connectedUserSubscription$.value);
    this.connectedUser$.value.activeSubscription = this.connectedUserSubscription$.value;
    this.connectedUser$.next(this.connectedUser$.value);
  }

  // User & OAuthUser
  setAuthUser(user: OAuthUser): void {
    this.oAuthUser$.next(user);
  }

  setConnectedUser(user: User): void {
    user.activeSubscription = this.connectedUserSubscription$.value;
    this.connectedUser$.next(user);

    this.setMedicalPractices(user.medicalPractices);
    this.setCurrentMedicalPractice(user.medicalPractices.find((practice) => practice.id === user.currentMedicalPractice));
  }

  setConnectedUserSubscription(subscription: UserSubscription): void {
    this.connectedUserSubscriptionEventEmitted.emit(subscription);
    this.connectedUserSubscription$.next(subscription);
    this.connectedUser$.value.activeSubscription = subscription;
    this.connectedUserSubscription$.next(this.connectedUserSubscription$.value);
    this.connectedUser$.next(this.connectedUser$.value);
  }

  // Calendar
  setCurrentCalendarWeekEvents(events: ConsultationEvent[]): void {
    this.currentCalendarWeekEvents$.next(events);
  }

  addEventToCurrentCalendarWeekEvents(event: ConsultationEvent): void {
    this.currentCalendarWeekEvents$.value.push(event);
    this.currentCalendarWeekEvents$.next(this.currentCalendarWeekEvents$.value);
  }

  removeEventToCurrentCalendarWeekEventsById(eventId: string): void {
    this.currentCalendarWeekEvents$.next(this.currentCalendarWeekEvents$.value.filter((event) => event.id !== eventId));
  }

  // Medical Practice
  setCurrentMedicalPractice(practice: MediacalPractice): void {
    this.currentMedialPractice$.next(practice);
  }

  setMedicalPractices(practices: MediacalPractice[]): void {
    this.medialPractices$.next(practices);
  }

  setMedicalPracticesDropdown(practices: Dropdown[]): void {
    this.medicalPracticesDropdown$.next(practices);
  }

  // Patients
  setPatients(patients: Patient[]): void {
    this.patients$.next(patients);
  }

  setMinimalPatients(patients: PatientMinimal[]): void {
    this.minimalPatients$.next(patients);
  }

  addMinimalPatient(patient: PatientMinimal): void {
    if (!this.minimalPatientContains(patient.id)) {
      this.minimalPatients$.next(this.minimalPatients$.value.concat(patient));
    }
    this.minimalPatients$.next(this.minimalPatients$.value);
  }

  addMinimalPatients(patients: PatientMinimal[]): void {
    const diff = patients.filter((patient) => !this.minimalPatientContains(patient.id));
    this.minimalPatients$.next(this.minimalPatients$.value.concat(diff));
  }

  minimalPatientContains(id: string): boolean {
    return this.minimalPatients$.value.some((patient) => patient.id === id);
  }

  getMinialPatientById(id: string): PatientMinimal {
    return this.minimalPatients$.value.find((patient) => patient.id === id);
  }

  // Patient Extended
  setCurrentPatientExtended(patient: PatientExtended): void {
    patient.consultations = this.currentPatientExtendedConsultations;
    this.currentPatientExtended$.next(patient);
  }

  setCurrentPatientExtendedConsultations(consultations: ConsultationExtended[]): void {
    this.currentPatientExtendedConsultations = consultations;
    this.currentPatientExtended$.value.consultations = consultations;
    this.currentPatientExtended$.next(this.currentPatientExtended$.value);
  }

  // Antecedent
  setCurrentAntecedent(antecedent: MedicalHistoryExtended): void {
    if (antecedent.id === this.currentAntecedent$.value.id) {
      antecedent.documents = this.currentAntecedentDocuments$.value;
    } else {
      antecedent.documents = [];
    }
    this.currentAntecedent$.next(antecedent);
  }

  setCurrentAntecedentDocuments(documents: EDMDocument[]): void {
    this.currentAntecedentDocuments$.next(documents);
    this.currentAntecedent$.value.documents = this.currentAntecedentDocuments$.value;
    this.currentAntecedent$.next(this.currentAntecedent$.value);
  }

  // Consultation Acte Data
  setConsultationActeData(acteData: ActData[]): void {
    this.consultationActeData$.next(acteData);
  }

  setCurrentConsultationDocuments(documents: EDMDocument[]): void {
    this.currentConsultationDocuments$.next(documents);
  }

  // Antecedents Types
  setAntecedentsTypes(antecedentsTypes: Dropdown[]): void {
    this.antecedentsTypes$.next(antecedentsTypes);
  }

  // Consultation Types
  setConsultationTypes(consultationTypes: ColorDropdown[]): void {
    this.consultationTypes$.next(consultationTypes);
  }
}
